<template><div><h1 id="compiling-custom-firmware" tabindex="-1"><a class="header-anchor" href="#compiling-custom-firmware"><span>Compiling Custom Firmware</span></a></h1>
<h2 id="cloning-building-roms" tabindex="-1"><a class="header-anchor" href="#cloning-building-roms"><span>Cloning &amp; Building ROMs</span></a></h2>
<div class="custom-container danger"><p class="custom-container-title">DANGER</p>
<p>Building and flashing your own firmware has the potential to brick your device. Do not do this unless you are sure you know what you are doing/have a way to recover from a bad flash. Some level of knowledge with using the Linux command line is required.</p>
</div>
<ol>
<li><strong>Install tools and libraries needed for coreboot:</strong>
<ul>
<li>Debian based distros: <code v-pre>sudo apt install -y bison build-essential curl flex git gnat imagemagick libncurses5-dev m4 nasm python-is-python3 uuid-dev zlib1g-dev libssl-dev</code></li>
<li>Arch based distros: <code v-pre>sudo pacman --needed -S base-devel curl git gcc-ada ncurses zlib nasm imagemagick</code></li>
<li>Redhat based distros: <code v-pre>sudo dnf install git make gcc-gnat flex bison xz bzip2 gcc g++ ncurses-devel wget zlib-devel patch openssl libuuid-devel nasm texinfo</code></li>
</ul>
</li>
<li><strong>Clone the repository:</strong>
<ul>
<li><code v-pre>git clone https://github.com/mrchromebox/coreboot.git</code></li>
</ul>
</li>
<li><strong><code v-pre>cd</code> to the coreboot folder, then build the coreboot toolchain</strong>
<ul>
<li><code v-pre>make crossgcc-i386 CPUS=$(nproc)</code></li>
</ul>
</li>
<li><strong>Make changes now, if needed.</strong>
<ul>
<li>Common changes include:
<ul>
<li>Replacing the default logo (<code v-pre>Documentation/coreboot_logo.bmp</code>)</li>
<li>Changing version string (<code v-pre>CONFIG_LOCALVERSION</code>)</li>
</ul>
</li>
</ul>
</li>
<li><strong>Build the ROM</strong>
<ul>
<li>create build dir: <code v-pre>mkdir -p ~/dev/firmware</code></li>
<li><code v-pre>./build-uefi.sh &lt;boardname&gt;</code>
<ul>
<li>For example, <code v-pre>./build-uefi.sh careena</code></li>
<li>ROMs will be stored in <code v-pre>~/dev/firmware</code></li>
</ul>
</li>
</ul>
</li>
<li><strong>Continue onto <RouteLink to="/docs/firmware/manually-flashing.html">Flashing Manually</RouteLink> to flash</strong></li>
</ol>
</div></template>


